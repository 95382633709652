import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Teknik Sorunlara Hızlı Çözümünüz
			</title>
			<meta name={"description"} content={"TechEase Solutions olarak her teknik sorunun bir çözümü olduğuna inanıyoruz."} />
			<meta property={"og:title"} content={"Teknik Sorunlara Hızlı Çözümünüz"} />
			<meta property={"og:description"} content={"TechEase Solutions olarak her teknik sorunun bir çözümü olduğuna inanıyoruz."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13:23:10.855Z"
						display="block"
						max-width="100%"
						width="350px"
						border-radius="30px"
						srcSet="https://smartuploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13%3A23%3A10.855Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13%3A23%3A10.855Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13%3A23%3A10.855Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13%3A23%3A10.855Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13%3A23%3A10.855Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13%3A23%3A10.855Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-4%20%281%29.jpg?v=2024-07-08T13%3A23%3A10.855Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Kapsamlı Teknik Bakım Burada Başlıyor
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						TechEase Solutions'ın yalnızca sorunları çözmediğimiz, aynı zamanda önlediğimiz özel hizmetler sayfasına hoş geldiniz. Teknik onarım ortağınız olarak, tüm elektronik ihtiyaçlarınızı karşılayacak şekilde tasarlanmış bir hizmet paketi sunuyoruz. İster arızalı bir bilgisayarla uğraşıyor olun ister önleyici bakım istiyor olun, uzman teknisyenlerimiz üst düzey destek sağlamak için buradalar.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
			color="--darkL1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Özel Hizmetlerimiz
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					TechEase Solutions olarak amacımız cihazlarınızın ömrünü uzatmak ve yeni gibi performans göstermelerini sağlamaktır. Teknisyenlerimiz çeşitli sorunları hassas ve dikkatli bir şekilde ele alma konusunda uzmandır ve teknik endişelerinizin geçmişte kalmasını sağlar.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					<Strong>
						Tekliflerimizin kapsamını keşfedin. Her hizmet, teknolojinizi en yüksek performansına geri getirecek şekilde özel olarak tasarlanmıştır:
					</Strong>
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Donanım Onarımları ve Yükseltmeleri
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Ekran Değişimleri: Hızlı ekran onarımlarımızla çatlaklara ve ekran sorunlarına elveda deyin.
Pil Değiştirmeler: Yüksek kaliteli pil değiştirmelerle cihazınızın ömrünü uzatın.
Bileşen Onarımları: Anakartlardan grafik kartlarına kadar tüm önemli bileşenlerle ilgileniyoruz.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Yazılım Sorun Giderme
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Virüs Temizleme: Kapsamlı kötü amaçlı yazılım temizleme yöntemimizle verilerinizi ve hızınızı koruyun.
Yazılım Güncellemeleri: Sisteminizi en son yazılım sürümleriyle güncel tutun.
Performans Optimizasyonu: İnce ayarlarımızla cihazınızın hızını ve verimliliğini artırın.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Veri yönetimi
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Veri Kurtarma: Dosyalarınız mı kayboldu? Sorun değil. Kurtarma hizmetlerimiz önemli verilerinizi geri yükleyebilir.
Yedekleme Çözümleri: Güçlü veri yedekleme seçeneklerimizle bilgilerinizi koruyun.
Veri Aktarımı: Veri taşıma hizmetlerimizle cihazları sorunsuz bir şekilde yükseltin veya değiştirin.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Koruyucu Bakım
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Sistem Tanılaması: Sorunları ciddileşmeden önce tespit etmek için düzenli kontroller.
Temizlik Hizmetleri: Cihazınızın bozulmamış durumda kalması için toz alma ve temizleme.
Termal Yönetim: Optimum performansı korumak için aşırı ısınma çözümleri.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					TechEase Çözümlerinin sunabileceği her şeyi keşfetmeyi mi merak ediyorsunuz? Bu liste sadece başlangıç. Sağladığımız hizmetlerin tamamını keşfetmek için bizi ziyaret edin veya ekibimizle iletişime geçin. Teknolojiyi yönetmenin ne kadar kolay ve stressiz olabileceğini size gösterelim!
				</Text>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});